import { MAX_LOGOUT_COMMENTS, MAX_POLL_DATA } from '@/constants/gpc'
import type { PartialProfileType } from '@/constants/profile'
import { TagNames, TagSlugs } from '@/constants/tagInfo'
import type {
  AwardDTO,
  ChannelType,
  ChartDataType,
  ChartDatasetItem,
  CommentType,
  ConferenceBanner,
  GroupMembershipType,
  LogoutPost,
  PostType,
  ProfileAwardCount,
  ProfileAwardType,
  ProfileSkillType,
  ProfileStatsType,
  ProfileType,
  PublicCommentType,
  PublicCommunityType,
  PublicConferenceBanner,
  PublicGroupMembership,
  PublicPollDataSummaryType,
  PublicPostType,
  PublicProfileType,
  PublicReplyType,
  PublicTopicType,
  SkillDTO,
  StatsDTO,
  StringOrNumberType,
} from '@/types/api'
import { UpvoteTypes } from '@/types/api'
import { CommentSortBy, type PublicCommentSortFn } from '@/types/common'
import { getFromNow } from '@/util/date'
import {
  countCommentsAndReplies,
  getCompanySizeLabel,
  getIndustryLabel,
  getLabel,
  getPostPath,
  isReportType,
} from '@/util/post'
import { isSeoPage } from '@/util/seo'
import { UserStatus } from '../profile/constants'

const EmptyProfile: PartialProfileType = {
  _id: '',
  titledisplay: 'no title',
  company_size: 1,
  gpiindustries: [0],
  contribution_points: 0,
  pulsepoints: 0,
  pic: '',
  displayname: '',
  email: '',
  slug: '',
  country: '',
  is_ambassador: false,
  firstname: '',
  company_name: '',
  title_override: '',
  departments: {
    ids: [],
    last_updated: '',
  },
  verification_state: 0,
  first_seen: '',
  customer: false,
  level: undefined,
  primary_gpi_function: undefined,
  additional_gpi_functions: [],
  groups_membership: null,
  channels: [],
  connections_count: 0,
  conference_banners: [],
  public_communities: [],
}

const mapProfiles = (profiles: ProfileType[]): Map<string, ProfileType> => {
  const profileMap = new Map<string, ProfileType>()
  profiles.forEach((x) => profileMap.set(x._id, x))
  return profileMap
}
type OptionSum = { [key: string]: number }

const getChartLabels = (chartdata: ChartDataType[] = []): string[] =>
  chartdata.map((cd) => cd.title.replace('202', "'2")).slice(MAX_POLL_DATA * -1)

const getChartDataSet = (chartdata: ChartDataType[], options: string[]): ChartDatasetItem[] => {
  const dict: { [x: string]: StringOrNumberType[] } = {}
  chartdata.forEach((data) => {
    const percentageGroup = data.groups
    percentageGroup.forEach(({ label, data: value }) => {
      dict[label] = dict[label] || []
      dict[label].push(value)
    })
  })
  const dataset = options.map((opt) => ({
    label: opt,
    data: (dict[opt] || []).slice(MAX_POLL_DATA * -1),
  }))
  return dataset
}

export const transformToPublicPollDataSummary = ({
  options = [],
  chartdata = [],
}: Partial<PostType>): PublicPollDataSummaryType => {
  const shouldChartRender = chartdata?.length > 1
  const labels = getChartLabels(chartdata)
  const datasets = getChartDataSet(chartdata, options)
  const participants = chartdata.reduce((prev, cur) => cur.numresponses + prev, 0)
  const dividend = chartdata.length || 1
  const optionSums: OptionSum = {}
  chartdata.forEach(({ groups = [] }) =>
    groups.forEach(({ data, label }) => {
      const sum = optionSums[label] || 0
      optionSums[label] = sum + Number.parseInt(`${data}`)
    })
  )
  const maxSum = Math.max(...Object.values(optionSums))
  const results = options.map((label) => {
    const tmpSum = optionSums[label] || 0
    const winner = tmpSum === maxSum
    const data = Math.floor(tmpSum / dividend)
    return { label, data, winner }
  })
  const dataSource = { labels, datasets }
  return { participants, results, dataSource, shouldChartRender }
}

export const transformProfileTitle = (
  profile: Pick<ProfileType, 'titledisplay' | 'title_override' | 'company_name'>
) => {
  const { titledisplay, company_name, title_override } = profile
  return (
    title_override ||
    (titledisplay && company_name && `${titledisplay} at ${company_name}`) ||
    titledisplay ||
    company_name
  )
}

export const getChannelsFromGroupMembership = (
  groupsMembership: GroupMembershipType[],
  channels: ChannelType[]
): PublicGroupMembership[] => {
  const groupMembershipChannels = groupsMembership[0]?.channels ?? []

  return groupMembershipChannels.flatMap((channel) => {
    const profileChannel = channels?.find(({ slug }) => slug === channel.slug)
    if (!profileChannel) {
      return []
    }
    return { name: profileChannel.name, slug: profileChannel.slug }
  })
}

export const getProfilePicture = (url: string) => {
  if (url?.includes('peerProfileDefault')) {
    return ''
  }

  return url
}

export const transformConferenceBanner = ({
  page_slug,
  page_title,
  destination,
  duration_text,
}: ConferenceBanner): PublicConferenceBanner => ({
  pageSlug: page_slug,
  pageTitle: page_title,
  destination,
  durationText: duration_text,
})

export const transformToPublicProfile = ({
  titledisplay,
  gpiindustries,
  company_size,
  contribution_points,
  pulsepoints,
  pic: picParam,
  displayname,
  email,
  slug,
  country,
  social_media,
  is_ambassador: isAmbassador,
  firstname,
  _id: id,
  company_name: companyName,
  departments: departmentsProp,
  title_override,
  verification_state,
  is_readonly,
  access_form_required,
  use_split_access_form,
  consumption_access_form_required,
  contribution_access_form_required,
  has_demo_access,
  is_points_required,
  connections_count,
  first_seen: firstSeen,
  customer: isCustomer,
  level,
  primary_gpi_function: primaryFunction,
  groups_membership,
  channels,
  additional_gpi_functions,
  stats,
  conference_banners,
}: PartialProfileType): PublicProfileType => {
  const size = getCompanySizeLabel(company_size)
  const gpiInd = gpiindustries?.[0] || 0
  const industry = getIndustryLabel(gpiInd)
  const companySize = (size && `${size} employees`) || 'Self-employed'
  const isAnonymous = titledisplay === 'No Title - Anonymous'
  const isVerified = verification_state === UserStatus.USER_VERIFIED
  const isUserBlocked = verification_state === UserStatus.USER_BLOCKED
  const title = transformProfileTitle({
    title_override,
    company_name: companyName,
    titledisplay,
  })
  const pic = getProfilePicture(picParam)
  const departments = departmentsProp?.ids ?? []
  const isReadOnly = is_readonly ?? true
  const contributionPoints = contribution_points ?? 0
  const pulsePoints = pulsepoints ?? 0
  const hasDemoAccess = has_demo_access ?? false
  const connectionsCount = connections_count ?? 0
  const groupsMembership = getChannelsFromGroupMembership(groups_membership ?? [], channels ?? [])
  const hasPeerFinderAccess = false
  const isPointsRequired = is_points_required ?? true
  const accessFormRequired = access_form_required ?? false
  const useSplitAccessForm = use_split_access_form ?? false
  const consumptionAccessFormRequired = consumption_access_form_required ?? false
  const contributionAccessFormRequired = contribution_access_form_required ?? false
  const additionalGpiFunctions = additional_gpi_functions ?? []
  const publicStats = transformToPublicProfileStats(stats)
  const conferenceBanners = (conference_banners || [])
    .filter(({ type }) => type === 'conference')
    .map(transformConferenceBanner)
  const publicCommunities: PublicCommunityType[] = []

  const profile: PublicProfileType = {
    id,
    title,
    industry,
    companySize,
    companyName,
    isAnonymous,
    contributionPoints,
    pulsePoints,
    pic,
    displayname,
    email,
    slug,
    country,
    isAmbassador,
    isVerified,
    firstname,
    departments,
    isReadOnly,
    hasDemoAccess,
    connectionsCount,
    firstSeen,
    isCustomer,
    level,
    primaryFunction,
    additionalGpiFunctions,
    groupsMembership,
    hasPeerFinderAccess,
    isPointsRequired,
    accessFormRequired,
    useSplitAccessForm,
    consumptionAccessFormRequired,
    contributionAccessFormRequired,
    isUserBlocked,
    publicStats,
    conferenceBanners,
    publicCommunities,
  }

  if (social_media && (social_media.twitter_url || social_media?.linkedin_url)) {
    profile.socialMedia = {
      twitterUrl: social_media.twitter_url,
      linkedInUrl: social_media.linkedin_url,
    }
  }
  return profile
}

const transformToLogoutReply = (reply: CommentType, index: number, post: PostType, commentIdx: number) => {
  return {
    postId: post._id,
    postType: post.type,
    createdate: reply.createdate,
    fromNow: getFromNow(reply.createdate),
    text: reply.text,
    likes: reply.upvoters.length,
    profile: transformToPublicProfile(EmptyProfile),
    commentIndex: commentIdx,
    replyIndex: index,
    entityType: UpvoteTypes.REPLY,
    stats: {
      views: 0,
      likes: reply.upvoters?.length || 0,
      totalComments: reply.replies?.length || 0,
    },
  }
}

const transformToPublicReply = (
  reply: CommentType,
  profiles: Map<string, ProfileType>,
  index: number,
  post: PostType,
  commentIdx: number
): PublicReplyType => {
  const profile = profiles.get(reply.profileid) || EmptyProfile
  return {
    postId: post._id,
    postType: post.type,
    createdate: reply.createdate,
    fromNow: getFromNow(reply.createdate),
    text: reply.text,
    likes: reply.upvoters.length,
    profile: transformToPublicProfile(profile),
    commentIndex: commentIdx,
    replyIndex: index,
    upvoters: reply.upvoters,
    entityType: UpvoteTypes.REPLY,
    stats: {
      views: 0, // not tracked atm
      likes: reply.upvoters?.length || 0,
      totalComments: reply.replies?.length || 0,
    },
  }
}

const transformToLogoutComment = (
  comment: CommentType,
  profiles: Map<string, ProfileType>,
  index: number,
  post: PostType
) => {
  const profile = profiles.get(comment.profileid) || EmptyProfile

  return {
    createdate: comment.createdate,
    fromNow: getFromNow(comment.createdate),
    text: comment.text,
    likes: comment.upvoters.length,
    profile: transformToPublicProfile(profile),
    replies: comment.replies?.map((reply, idx) => transformToLogoutReply(reply, idx, post, index)) || [],
    commentIndex: index,
    postId: post._id,
    postType: post.type,
    entityType: UpvoteTypes.COMMENT,
    stats: {
      views: 0,
      likes: comment.upvoters?.length || 0,
      totalComments: comment.replies?.length || 0,
    },
  }
}

const transformToPublicComment = (
  comment: CommentType,
  profiles: Map<string, ProfileType>,
  index: number,
  post: PostType
) => {
  const profile = profiles.get(comment.profileid) || EmptyProfile
  const publicComment: PublicCommentType = {
    createdate: comment.createdate,
    fromNow: getFromNow(comment.createdate),
    text: comment.text,
    likes: comment.upvoters.length,
    profile: transformToPublicProfile(profile),
    replies: comment.replies?.map((r, idx) => transformToPublicReply(r, profiles, idx, post, index)) || [],
    commentIndex: index,
    postId: post._id,
    postType: post.type,
    upvoters: comment.upvoters,
    entityType: UpvoteTypes.COMMENT,
    stats: {
      views: 0, // not tracked atm
      likes: comment.upvoters?.length || 0,
      totalComments: comment.replies?.length || 0,
    },
  }
  return publicComment
}

const getTimestampFromId = (id: string) => Number.parseInt(id.substring(0, 8), 16) * 1000

function transformToPublicPostWithMappedProfiles(
  post: PostType,
  mappedProfiles: Map<string, ProfileType>,
  allTags: PublicTopicType[],
  channel?: ChannelType
): PublicPostType {
  const created = getTimestampFromId(post._id)
  return {
    _id: post._id,
    postType: post.type,
    postId: post._id,
    created,
    fromNow: getFromNow(created),
    text: post.text,
    contentType: getLabel(post),
    stats: {
      views: post.views,
      likes: post.upvoters?.length || 0,
      totalComments: countCommentsAndReplies(post),
    },
    options: post.options,
    chartdata: post.chartdata,
    comments: post.comments?.map((x, idx) => transformToPublicComment(x, mappedProfiles, idx, post)) || [],
    tags: post.tag_ids?.map((id) => {
      const { name = '', slug = '' } = allTags.find((tag) => tag.id === id) ?? {}
      return { text: name, slug }
    }),
    num_responses: post.num_responses,
    source: post.source,
    type: post.type,
    slug: post.slug,
    path: getPostPath(post),
    pollSummary: transformToPublicPollDataSummary(post),
    isSeoIndexable: isSeoPage(post),
    isSurvey: !!post.parent,
    upvoters: post.upvoters,
    entityType: UpvoteTypes.POST,
    departments: post.departments,
    accessType: post.access_type,
    channel,
    channelSlug: post.channel_slug,
    isSaved: post.is_saved,
    isReadOnly: post.is_readonly,
    publicCommunities: post.public_communities ?? [],
  }
}

export function filterCommentsAndReplies(post: PostType): Pick<PostType, 'comments' | 'num_comments'> {
  if (!post.comments?.length) {
    return { comments: [], num_comments: 0 }
  }
  const limitedComments = post.comments.slice(0, MAX_LOGOUT_COMMENTS)

  const result = limitedComments.map((comment, index) => {
    if (!comment.replies?.length) {
      return { ...comment, replies: null }
    }

    const availableReplySlots = Math.max(0, MAX_LOGOUT_COMMENTS - index - 1)
    const filteredReplies = comment.replies.slice(0, availableReplySlots)

    return {
      ...comment,
      replies: filteredReplies.length > 0 ? filteredReplies : null,
    }
  })

  return {
    comments: result,
    num_comments: result.length,
  }
}

export const transformToLogoutPost = (
  post: PostType,
  profiles: ProfileType[],
  allTags: PublicTopicType[],
  channel?: ChannelType
): LogoutPost => {
  const mappedProfiles = mapProfiles(profiles)
  const created = getTimestampFromId(post._id)
  // const { comments, num_comments } = filterCommentsAndReplies(post)

  return {
    _id: post._id,
    postType: post.type,
    postId: post._id,
    created,
    fromNow: getFromNow(created),
    text: post.text,
    contentType: getLabel(post),
    stats: {
      views: post.views,
      likes: post.upvoters?.length || 0,
      totalComments: countCommentsAndReplies(post),
    },
    options: post.options,
    chartdata: post.chartdata,
    comments: post.comments?.map((comment, idx) => transformToLogoutComment(comment, mappedProfiles, idx, post)) || [],
    tags: post.tag_ids?.map((id) => {
      const { name = '', slug = '' } = allTags.find((tag) => tag.id === id) ?? {}
      return { text: name, slug }
    }),
    num_responses: post.num_responses,
    departments: post.departments?.map((id) => id) ?? null,
    source: post.source,
    type: post.type,
    slug: post.slug,
    path: getPostPath(post),
    pollSummary: transformToPublicPollDataSummary(post),
    isSeoIndexable: isSeoPage(post),
    isSurvey: !!post.parent,
    entityType: UpvoteTypes.POST,
    accessType: post.access_type,
    channel,
    channelSlug: post.channel_slug,
    isSaved: post.is_saved,
    isReadOnly: post.is_readonly,
    publicCommunities: post.public_communities,
  }
}
export const transformToPublicPost = (
  post: PostType,
  profiles: ProfileType[],
  allTags: PublicTopicType[],
  channel?: ChannelType
): PublicPostType => {
  const mappedProfiles = mapProfiles(profiles)
  return transformToPublicPostWithMappedProfiles(post, mappedProfiles, allTags, channel)
}

export const transformToLogoutRelatedPosts = (
  posts: PostType[],
  profiles: ProfileType[],
  allTags: PublicTopicType[]
): LogoutPost[] =>
  posts.filter((p) => !isReportType(p.type)).map((post) => transformToLogoutPost(post, profiles, allTags))

export const transformToPublicRelatedPosts = (
  posts: PostType[],
  profiles: ProfileType[],
  allTags: PublicTopicType[]
): PublicPostType[] => {
  const mappedProfiles = mapProfiles(profiles)
  return posts
    .filter((p) => !isReportType(p.type))
    .map((post) => transformToPublicPostWithMappedProfiles(post, mappedProfiles, allTags))
}

export const transformToPublicProfileStats = (
  stats: StatsDTO = { written_answers_count: 0, upvotes_received_count: 0, profile_viewed_count: 0 }
): ProfileStatsType => ({
  answers: stats.written_answers_count,
  upvotes: stats.upvotes_received_count,
  views: stats.profile_viewed_count,
})

type AwardAccType = { [key: string]: ProfileAwardType }

export const transformToPublicProfileAwards = (awards: AwardDTO[] | null): ProfileAwardCount => {
  if (!awards) {
    return {
      total: 0,
      items: [],
    }
  }

  const total = awards.length

  const grouped = awards.reduce((acc: AwardAccType, curr: AwardDTO) => {
    const { tag_id, tag_name } = curr
    const existingTag = acc[tag_id]
    if (existingTag) {
      existingTag.count++
    } else {
      acc[tag_id] = { id: tag_id, name: tag_name, count: 1 }
    }
    return acc
  }, {} as AwardAccType)

  const sorted = Object.values(grouped).sort((a, b) => b.count - a.count)

  return { total, items: sorted }
}

export const transformToPublicProfileSkills = (skills: SkillDTO[] | null): ProfileSkillType[] => {
  if (!skills) {
    return []
  }
  return skills
    .filter(({ tag_id, expertise }) => {
      const tagExists = TagNames[tag_id] !== undefined
      const hasExpertise = expertise.declared || (!expertise.declared && expertise.level > 0)
      return tagExists && hasExpertise
    })
    .map(({ tag_id, expertise, interest }) => {
      const skill: ProfileSkillType = {
        id: tag_id,
        name: TagNames[tag_id],
        level: expertise.level,
        slug: TagSlugs[tag_id],
        selfDeclared: expertise.declared,
      }

      if (interest.declared) {
        skill.level++
      }

      return skill
    })
    .sort((a, b) => b.level - a.level)
}

type SortFn = PublicCommentSortFn
export const oldestFn: SortFn = (a, b) => new Date(a.createdate).getTime() - new Date(b.createdate).getTime()
export const newestFn: SortFn = (a, b) => oldestFn(b, a)
export const mostUpvotesFn: SortFn = (a, b) => b.likes - a.likes
export const commentSortFunctions: {
  [x in CommentSortBy]: PublicCommentSortFn
} = {
  [CommentSortBy.oldest]: oldestFn,
  [CommentSortBy.newest]: newestFn,
  [CommentSortBy.mostUpvoted]: mostUpvotesFn,
} as const
